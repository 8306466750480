import React from 'react';
import theme from '../../../theme';
import styled from 'styled-components';

export const Inner = styled.div`
  margin: 0 auto;
  width: ${(props) => (props.width ? props.width : theme.layoutWidth)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : theme.layoutWidthMax)};
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const CardImg = styled.div`
  img {
    ${(props) => props.customCSS && props.customCSS};
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
`;

export const CardWrapper = styled.div`
  overflow: hidden;
  @media (min-width: 0) {
    display: block;
    width: 100%;
  }
  @media (min-width: 550px) {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin: 1rem 0.5rem;
  }
  @media (min-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 1rem 0.5rem;
  }
  @media (min-width: 1200px) {
    margin: 1rem 1rem;
  }

  .inner-content {
    display: flex;
    flex-direction: column;
    p {
      margin: 0;
      font-family: Lato, sans-serif;
      font-size: 18px;
      color: #60616F;
      text-align: left;
      font-weight: 300;
      line-height: 1.8;
    }
    h3 {
      font-size: 28px;
      color: var(--base-color);
      margin: .5rem 0 0 0;
      text-align: left;
    }
    a {
      font-size: 20px;
      font-weight: 500;
      color: var(--link-button-color);
      text-decoration: none;
    }
    @media (min-width: 400px){
      h3 {
        margin: 1rem 0;
      }
      p {
        margin: 1rem 0;
      }
    }
    @media (min-width: 768px){
      h3 {
        font-size: 32px;
      }
    }
  }
  .link-wrapper {
    p {
      margin: 0 0 1.5rem 0;
      font-family: Lato, sans-serif;
      font-size: 18px;
      color: #60616F;
      text-align: left;
      font-weight: 300;
      line-height: 1.8;
    }
    a {
      font-size: 20px;
      font-weight: 500;
      color: var(--link-button-color);
      text-decoration: none;
    }
    @media (min-width: 400px) {
      margin: 1rem 0;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;
