import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import HeadHelmet from '../../../components/head';
import ContactDiv from '../../../components/contactUs';
import Layout from '../../../components/layout';
import { GetQuoteDiv } from '../../../components/pages/solutions/getQuoteDiv';
import { CardImg, CardWrapper, Inner} from '../../../components/pages/solutions/card';
import { StyledBackground } from '../../../components/ui-elements/backgroundImage';

const SolutionsBySector = ({ data }) => {
  const { strapiSsSolutionsSector } = data;
  const {
    title,
    solutions_section_cards,
    hero_image_solutions_sector_page,
    sectionUnderHero } = strapiSsSolutionsSector || {};
  return (
    <>
      <HeadHelmet data={strapiSsSolutionsSector.MetaData} />
      <Layout>
        <StyledBackground
        img={hero_image_solutions_sector_page.childImageSharp.fluid}
        title={title}
        titleWidth={'100%'}
        />
            <GetQuoteDiv>
              <ReactMarkdown>{sectionUnderHero}</ReactMarkdown>
            </GetQuoteDiv>
        <Inner>
          {solutions_section_cards.map((item) => {
            return (
              <CardWrapper key={item.id}>
                <Link to={'/'}>
                  <CardImg>
                    <Img
                      fluid={item.image.childImageSharp.fluid}
                      alt={item.alternativeText || `${title} - image`}
                    />
                  </CardImg>
                </Link>
                <ReactMarkdown className={'inner-content'}>{item.description}</ReactMarkdown>
              </CardWrapper>
            );
          })}
        </Inner>
        <ContactDiv />
      </Layout>
    </>
  );
};

export default SolutionsBySector;

export const pageQuery = graphql`
  query {
    strapiSsSolutionsSector {
      id
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      sectionUnderHero
      hero_image_solutions_sector_page {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      solutions_section_cards {
        id
        description
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
