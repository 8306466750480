import React from 'react';
import styled from 'styled-components';

export const GetQuoteDiv = styled.div`
  text-align: center;
  h1 {
    color: var(--base-color);
    font-size: 28px;
    font-weight: bold;
    font-family: futura-pt, sans-serif;
    margin: 0 1rem;
    margin-top: 11rem;
    text-align: center;
  }
  p {
    color: var(--base-color);
    font-size: ${props => props.fontSize || '18px'};
    font-family: ${props => props.fontFamily || 'Lato, sans-serif'};
    font-style: ${props => props.fontStyle || 'italic'};
    font-weight: ${props => props.fontWeight || 'lighter'};
  }
  @media (min-width: 0) {
    width: 100%;
  }
  @media (min-width: 400px) {
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 20px;
    }
  }
  @media (min-width: 960px) {
    width: 900px;
    h1 {
      font-size: 45px;
    }
    p {
      font-size: 22px;
    }
  }
  text-align: center;
  margin: 4rem auto;

  a {
    text-decoration: none;
    padding: 0.5rem 2rem;
    border-radius: 22px;
    box-shadow: 0 2px 10px #002f6c33;
    background-color: #ffffff;
    width: 200px;
    height: auto;
    font-size: 20px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    color: ${ props => props.anchorColor || '#ffffff'};
    background: ${ props => props.anchorBackground || 'var(--base-color)'};
  }
`;
